import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage
            src={mobileAbout}
            w="100%"
            alt="John's Fried Chicken About"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center w="100%" maxWidth="1400px">
          <CFImage src={about} w="81%" alt="Promotions" mb="30px" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
